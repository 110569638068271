'use client';

import { NextUIProvider } from '@nextui-org/react';

// prettier-ignore
export function Providers({children}: { children: React.ReactNode }) {
    return (
        <NextUIProvider>
            {children}
        </NextUIProvider>
    )
}
