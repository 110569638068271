import React from 'react';

interface ChevronDownProps {
    fill?: string;
    size?: number;
    height?: number;
    width?: number;
    className?: string;
    props?: any;
}

export default function ChevronDown({ fill, size, height, width, className, ...props }: ChevronDownProps) {
    return (
        <svg width={size || width || 24} height={size || height || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
            <path d="M6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289Z" fill={fill || 'currentColor'} />
        </svg>
    );
}
