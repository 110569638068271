export const navItems: any[] = [
    { key: 'home', label: 'home', link: '/' },
    {
        key: 'boxbyindustry',
        label: 'boxByIndustry',
        link: '/box-by-industry',
    },
    {
        key: 'boxbymaterial',
        label: 'boxByMaterial',
        link: '/box-by-material',
    },
    {
        key: 'boxbystyle',
        label: 'boxByStyle',
        link: '/box-by-style',
    },
    { key: 'blog', label: 'blog', link: '/blog' },
    { key: 'about', label: 'about', link: '/about' },
];
