import React, { useState } from 'react';
import { NavbarContent, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link } from '@nextui-org/react';
import { ChevronDown } from './Icons';
import { navItems } from './NavbarData';
import styles from './styles/navbar.module.css';
import { useTranslations } from 'next-intl';

export default function NavbarMobile({ isMenuOpen }: { isMenuOpen: boolean; handleMenuOpenChange: (isOpen: boolean) => void }) {
    const [openDropdown, setOpenDropdown] = useState('');

    const handleDropdownToggle = (key: string) => {
        setOpenDropdown(openDropdown === key ? '' : key);
    };

    const t = useTranslations('navbar');

    return (
        <>
            <NavbarContent justify="end" className="xl:hidden">
                <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} />
            </NavbarContent>
            <NavbarMenu className={styles.mobilenav}>
                {navItems.map((item) =>
                    item.dropdown ? (
                        <React.Fragment key={item.key}>
                            <NavbarMenuItem>
                                <Link color="foreground" className="w-full flex items-center justify-between" href="#" onClick={() => handleDropdownToggle(item.key)}>
                                    {t(item.label)}
                                    <ChevronDown className={`transition-transform ${openDropdown === item.key ? 'transform rotate-180' : ''}`} fill="currentColor" size={16} height={16} width={16} props={undefined} />
                                </Link>
                            </NavbarMenuItem>
                            {openDropdown === item.key && (
                                <div className="ml-4">
                                    {item.dropdown.map((dropdownItem: any) => (
                                        <NavbarMenuItem key={dropdownItem.key}>
                                            <Link color="foreground" className="w-full" href={`${dropdownItem.link}`}>
                                                {dropdownItem.label}
                                            </Link>
                                        </NavbarMenuItem>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <NavbarMenuItem key={item.key}>
                            <Link color="foreground" className="w-full" href={`${item.link}`}>
                                {t(item.label)}
                            </Link>
                        </NavbarMenuItem>
                    )
                )}
            </NavbarMenu>
        </>
    );
}
